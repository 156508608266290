import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { reportMsg } from "../../utils/tools";
import { useAppSelector } from "../../store/hooks";
import { selectSiteId } from "../../pages/home/homeSlice";

const Anchor = ({ adSlot, alias, id, path }) => {
  const location = useLocation();
  const uuid = localStorage.getItem("jlgames-client-id");
  const siteId = useAppSelector(selectSiteId);

  useEffect(() => {
    if (window.googletag) {
      window.googletag.cmd.push(function () {
        var slot = window.googletag.defineOutOfPageSlot(
          adSlot,
          window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
        );
        if (slot) slot.addService(window.googletag.pubads());

        window.googletag
          .pubads()
          .addEventListener("impressionViewable", (event) => {
            const slotId = event.slot.getSlotElementId();
            console.log("showed", slotId);
            reportMsg(uuid, id, 0, siteId, "showed");
          });

        window.googletag
          .pubads()
          .addEventListener("slotRenderEnded", (event) => {
            console.log("fill or no fill");
            if (event.isEmpty) {
              reportMsg(uuid, id, 0, siteId, "nofill");
            } else {
              reportMsg(uuid, id, 0, siteId, "fill");
            }
          });

        window.googletag.enableServices();
        window.googletag.display(slot);
      });

      if (location.pathname.includes(path)) {
        reportMsg(uuid, id, 0, siteId, "insert");
      }
    }

    return () => {
      if (window.googletag) {
        if (window.googletag?.destroySlots) {
          window.googletag?.destroySlots([adSlot]);
        }
      }
    };
  }, [location, adSlot]); // 监听路由变化

  return <div></div>;
};

export default Anchor;
